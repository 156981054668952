import React from 'react'

import Container from '@/common/components/Container'

const NotFoundPage = () => (
  <Container className='full-height flex flex-1 align-center justify-center py-48'>
    404 | Not Found
  </Container>
)

export default NotFoundPage
